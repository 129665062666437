import React, { useState } from "react";

import IconButton from "@mui/material/IconButton";
//import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LoginForm from "./LoginForm";
//import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

import "../Styles/user.css";

function User() {
  const [user, setUser] = useState(null); // null when not logged in, user object when logged in
  const [hover, setHover] = useState(false);

  const handleLogin = async (mail, password) => {
    try {
      const url = process.env.REACT_APP_URL + "user/login";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ mail, password }),
      });

      if (response.ok) {
        const data = await response.json();
        setUser(data.user); // Set the user object from the response
        console.log(data);
      } else {
        const error = await response.json();
        alert("Login failed: " + error.message);
      }
    } catch (error) {
      alert("An error occurred: " + error.message);
    }
  };

  const handleSignUp = async (mail, username, password) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_URL + "user/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ mail, username, password }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setUser(data); // Set the user object from the response
      } else {
        const error = await response.json();
        alert("Signup failed: " + error.message);
      }
    } catch (error) {
      alert("An error occurred: " + error.message);
    }
  };

  const handleLogout = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_URL + "user/logout", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        //body: JSON.stringify({ token: user.token }), // Assuming the token is part of the user object
      });

      if (response.ok) {
        setHover(false);
        setUser(null); // Clears the user state, effectively logging out the user
      } else {
        const error = await response.json();
        alert("Logout failed: " + error.message);
      }
    } catch (error) {
      alert("An error occurred: " + error.message);
    }
  };

  return (
    <div
      className="container"
      style={{
        top: "10px",
        right: "10px",
        maxWidth: "300px",
      }}
    >
      {user ? (
        <>
          {user.username}
          <IconButton>
            <SettingsIcon />
          </IconButton>
          <IconButton onClick={handleLogout}>
            <PowerSettingsNewIcon />
          </IconButton>
        </>
      ) : (
        <LoginForm onLogin={handleLogin} onSignUp={handleSignUp} /> // Render login form if not logged in
      )}
    </div>
  );
}

export default User;
