import React from "react";
//import Map from "./Map";
//import ExternalStateExample from "./MapB";
import MapFeatures from "./MapFeatures";
import Bandeau from "./UI/Control";
import Detail from "./UI/Detail";
import Version from "./UI/Version";
import Left from "./UI/Left";
import User from "./UI/User";

function App() {
  return (
    <div>
      <MapFeatures />
      <Bandeau />
      <Detail />
      <Version />
      <Left />
      <User />
    </div>
  );
}

export default App;
