import React, { useState } from "react";
import "../Styles/detail.css";
import { useConfig } from "../ConfigContext";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

function Detail() {
  const { features, setFeatures, idSelectedFeature, setIdSelectedFeature } =
    useConfig();
  const [editMode, setEditMode] = useState(false);
  const [editedName, setEditedName] = useState("");

  // Find the selected feature based on idSelectedFeature
  const selectedFeature = features.find(
    (feature) => feature.id === idSelectedFeature
  );

  // Initialize editedName when selecting a feature or switching features
  React.useEffect(() => {
    if (selectedFeature) {
      setEditedName(
        selectedFeature.name || `${selectedFeature.type} ${selectedFeature.id}`
      );
    }
  }, [selectedFeature]);

  const handleNameChange = (event) => {
    setEditedName(event.target.value);
  };

  const saveName = () => {
    const updatedFeatures = features.map((feature) => {
      if (feature.id === idSelectedFeature) {
        return { ...feature, name: editedName };
      }
      return feature;
    });
    setFeatures(updatedFeatures);
    setEditMode(false);
  };

  const handleRemove = () => {
    const newFeatures = features.filter(
      (feature) => feature.id !== idSelectedFeature
    );
    setFeatures(newFeatures);
  };

  const handleClose = () => {
    setIdSelectedFeature(null); // Assuming `setIdSelectedFeature` sets the ID to null, effectively closing the detail view
  };

  const formatLength = (length) => {
    if (length < 1000) {
      return `${length.toFixed(2)} m`;
    } else {
      return `${(length / 1000).toFixed(2)} km`;
    }
  };

  return (
    selectedFeature && (
      <div className="right-div">
        <IconButton aria-label="mouse" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        {editMode ? (
          <div>
            <input
              type="text"
              value={editedName}
              onChange={handleNameChange}
              onBlur={saveName}
              autoFocus
            />
          </div>
        ) : (
          <h1 onClick={() => setEditMode(true)}>{editedName}</h1>
        )}
        <p>
          {selectedFeature.infos &&
            selectedFeature.infos.length &&
            "length : " + formatLength(selectedFeature.infos.length)}
        </p>
        <p>
          {selectedFeature.infos &&
            selectedFeature.infos.diameter &&
            "diameter : " + formatLength(selectedFeature.infos.diameter)}
        </p>
        <p>
          {selectedFeature.infos &&
            selectedFeature.infos.area &&
            "area : " + formatLength(selectedFeature.infos.area) + "²"}
        </p>
        <button onClick={handleRemove} className="remove-button">
          Remove
        </button>
      </div>
    )
  );
}

export default Detail;
