import React, { createContext, useState, useContext } from "react";

const ConfigContext = createContext({
  color: "blue", // default color
  setColor: () => {}, // default empty function
  mode: "mouse", // default mode
  setMode: () => {}, // function to set mode
});

export const useConfig = () => useContext(ConfigContext);

export const ConfigProvider = ({ children }) => {
  const [color, setColor] = useState("#ff0000");
  const [mode, setMode] = useState("mouse");
  const [idSelectedFeature, setIdSelectedFeature] = useState();
  const [features, setFeatures] = useState([]);
  const [backgrounds, setBackgrounds] = useState([]);
  const [mapInit] = useState({ center: [51.505, -0.09], zoom: 13 });

  return (
    <ConfigContext.Provider
      value={{
        color,
        setColor,
        mode,
        setMode,
        idSelectedFeature,
        setIdSelectedFeature,
        features,
        setFeatures,
        backgrounds,
        setBackgrounds,
        mapInit,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};
