import React, { useEffect, useState } from "react";
import "../Styles/version.css";

function Version() {
  const [status, setStatus] = useState("Loading...");

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_URL + "status");
        const data = await response.json();
        setStatus(data.databaseStatus);
      } catch (error) {
        setStatus("Error");
      }
    };
    // Set an interval to fetch status every second
    const intervalId = setInterval(fetchStatus, 10000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
    //fetchStatus();
  }, []);

  const getStatusColor = () => {
    if (status === "Connected") return "green";
    if (status === "Disconnected") return "red";
    return "orange";
  };

  return (
    <div className="left-bottom-div">
      B.240629{" "}
      <div
        className="status-indicator"
        style={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          backgroundColor: getStatusColor(),
        }}
      ></div>
    </div>
  );
}

export default Version;
