import React, { useState, useEffect, useCallback } from "react";
import {
  MapContainer,
  TileLayer,
  Polyline,
  Circle,
  Polygon,
  Tooltip,
} from "react-leaflet";
import { useConfig } from "./ConfigContext";
import "leaflet/dist/leaflet.css";

const MapFeatures = () => {
  const { color, mode, mapInit, features, setFeatures, setIdSelectedFeature } =
    useConfig();
  const [map, setMap] = useState(null);
  const [firstPoint, setFirstPoint] = useState(true);

  // tmp
  const [polylineCoordinates, setPolylineCoordinates] = useState([]);
  const [polygonCoordinates, setPolygonCoordinates] = useState([]);
  const [circleStart, setCircleStart] = useState();
  const [radius, setRadius] = useState(0);

  const [oldMode, setOldMode] = useState();

  const onMove = useCallback(
    (e) => {
      if (!map) return;
      const mouseCoord = [e.latlng.lat, e.latlng.lng];
      switch (mode) {
        case "draw":
          if (!firstPoint) {
            const coords = features[features.length - 1].coordinates;
            const point = coords[coords.length - 1];
            setPolylineCoordinates([point, mouseCoord]);
          }
          break;
        case "circle":
          if (!firstPoint) {
            setRadius(map.distance(circleStart, mouseCoord));
          } else {
            setCircleStart(null);
          }
          break;
        case "polygon":
          if (polygonCoordinates.length >= 0) {
            setPolygonCoordinates((prev) => {
              const newArray = prev.slice(0, -1);
              return [...newArray, mouseCoord];
            });
          }
          break;
        default:
          break;
      }
    },
    [map, mode, firstPoint, features, circleStart, polygonCoordinates]
  );
  const onDown = useCallback((e) => {
    setIdSelectedFeature();
  });

  const onClick = useCallback(
    (e) => {
      if (!map) return;
      const mouseCoord = [e.latlng.lat, e.latlng.lng];
      switch (mode) {
        case "draw":
          // création d'une nouvelle polyline dont le premier point correspond aux coordonnées de la souris

          if (firstPoint) {
            const newFeature = {
              id: features.length + 1,
              type: "polyline",
              coordinates: [mouseCoord],
              color: color,
              infos: {
                length: 0,
              },
            };
            setFeatures((prev) => [...prev, newFeature]);
          } else {
            setFeatures((prev) =>
              prev.map((feature) => {
                if (
                  feature.id === features[features.length - 1].id &&
                  feature.type === "polyline"
                ) {
                  return {
                    ...feature,
                    coordinates: [...feature.coordinates, mouseCoord],
                    infos: {
                      length:
                        feature.infos?.length +
                        map.distance(
                          mouseCoord,
                          feature.coordinates[feature.coordinates.length - 1]
                        ),
                    },
                  };
                }
                return feature;
              })
            );
          }
          setFirstPoint(false);
          break;
        case "circle":
          if (firstPoint) {
            setCircleStart(mouseCoord);
            setFirstPoint(false);
          } else {
            const newFeature = {
              id: features.length + 1,
              type: "circle",
              center: circleStart,
              color: color,
              radius: map.distance(circleStart, mouseCoord),
              infos: {
                diameter: map.distance(circleStart, mouseCoord) * 2,
                area:
                  3.14 *
                  map.distance(circleStart, mouseCoord) *
                  map.distance(circleStart, mouseCoord),
              },
            };
            setFeatures((prev) => [...prev, newFeature]);
            setCircleStart(null);
            setRadius(0);
            setFirstPoint(true);
          }
          break;
        case "polygon":
          setPolygonCoordinates((prev) => {
            return [...prev, mouseCoord];
          });
          break;
        default:
          break;
      }
    },
    [map, mode, firstPoint, features, color, circleStart]
  );

  useEffect(() => {
    if (!map) return;
    if (oldMode !== mode) setFirstPoint(true);
    switch (oldMode) {
      case "circle":
        setCircleStart(null);
        setRadius(0);
        break;
      case "draw":
        setPolylineCoordinates([]);
        break;
      case "polygon":
        const newFeature = {
          id: features.length + 1,
          type: "polygon",
          coordinates: [polygonCoordinates.slice(0, -1)],
          color: color,
        };
        setFeatures((prev) => [...prev, newFeature]);
        setPolygonCoordinates([]);
        break;
      default:
        break;
    }
    switch (mode) {
      case "draw":
        map.getContainer().style.cursor = "crosshair";
        break;
      default:
        map.getContainer().style.cursor = "";
        break;
    }
    setOldMode(mode);
  }, [mode, oldMode, color]);

  useEffect(() => {
    if (!map) return;
    map.off("click", onClick);
    map.off("mousemove", onMove);
    map.off("mousedown", onDown);
    map.on("click", onClick);
    map.on("mousemove", onMove);
    map.on("mousedown", onDown);
    return () => {
      map.off("click", onClick);
      map.off("mousemove", onMove);
      map.off("mousedown", onDown);
    };
  }, [map, onClick, onMove]);

  const handleFeatureClick = (id) => {
    setIdSelectedFeature(id);
  };

  return (
    <MapContainer
      center={mapInit.center}
      zoom={mapInit.zoom}
      zoomControl={false}
      style={{ height: "100vh", width: "100%" }}
      ref={setMap}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {features.map((feature, index) => {
        const eventHandlers = {
          click: () => handleFeatureClick(feature.id),
          //mouseover: () => console.log("over"),
        };
        const tooltipContent = feature.name || `${feature.type} ${index}`;
        switch (feature.type) {
          case "polyline":
            return (
              <Polyline
                key={index}
                positions={feature.coordinates}
                color={feature.color}
                eventHandlers={eventHandlers}
              >
                <Tooltip>{tooltipContent}</Tooltip>{" "}
              </Polyline>
            );
          case "circle":
            return (
              <Circle
                key={index}
                center={feature.center}
                radius={feature.radius}
                color={feature.color}
                eventHandlers={eventHandlers}
              >
                <Tooltip>{tooltipContent}</Tooltip>{" "}
              </Circle>
            );
          case "polygon":
            return (
              <Polygon
                key={index}
                positions={feature.coordinates}
                color={feature.color}
                eventHandlers={eventHandlers}
              >
                <Tooltip>{tooltipContent}</Tooltip>{" "}
              </Polygon>
            );
          default:
            return null;
        }
      })}

      {mode === "draw" && polylineCoordinates && (
        <Polyline positions={polylineCoordinates} color={color} />
      )}
      {mode === "polygon" && polygonCoordinates && (
        <Polygon positions={polygonCoordinates} color={color} />
      )}
      {circleStart && (
        <Circle center={circleStart} radius={radius} color={color} />
      )}
    </MapContainer>
  );
};

export default MapFeatures;
