import React from "react";

import IconButton from "@mui/material/IconButton";
import MouseIcon from "@mui/icons-material/Mouse";
import DrawIcon from "@mui/icons-material/Draw";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import { useConfig } from "../ConfigContext";
import "../Styles/control.css";

// https://mui.com/material-ui/material-icons/

function Bandeau() {
  const { color, setColor, mode, setMode } = useConfig();

  return (
    <div className="bottom-div">
      <input
        type="color"
        value={color}
        onChange={(e) => {
          console.log(e.target.value);
          setColor(e.target.value);
        }}
        style={{ width: "32px" }}
      />

      <IconButton
        aria-label="mouse"
        onClick={() => {
          setMode("mouse");
        }}
        style={{
          color: mode === "mouse" ? "#ba9e6c" : "#69aa87",
          border: mode === "mouse" ? "solid 2px #69aa87" : "",
          margin: "5px",
        }}
      >
        <MouseIcon />
      </IconButton>

      <IconButton
        aria-label="draw"
        onClick={() => {
          mode === "draw" ? setMode("mouse") : setMode("draw");
        }}
        style={{
          color: mode === "draw" ? "#ba9e6c" : "#69aa87",
          border: mode === "draw" ? "solid 2px #69aa87" : "",
        }}
      >
        <DrawIcon />
      </IconButton>

      <IconButton
        aria-label="circle"
        onClick={() => {
          mode === "circle" ? setMode("mouse") : setMode("circle");
        }}
        style={{
          color: mode === "circle" ? "#ba9e6c" : "#69aa87",
          border: mode === "circle" ? "solid 2px #69aa87" : "",
        }}
      >
        <PanoramaFishEyeIcon />
      </IconButton>

      <IconButton
        aria-label="polygon"
        onClick={() => {
          mode === "polygon" ? setMode("mouse") : setMode("polygon");
        }}
        style={{
          color: mode === "polygon" ? "#ba9e6c" : "#69aa87",
          border: mode === "polygon" ? "solid 2px #69aa87" : "",
        }}
      >
        <CropSquareIcon />
      </IconButton>

      <IconButton
        aria-label="location"
        onClick={() => {
          mode === "location" ? setMode("mouse") : setMode("location");
        }}
        style={{
          color: mode === "location" ? "#ba9e6c" : "#69aa87",
          border: mode === "location" ? "solid 2px #69aa87" : "",
        }}
      >
        <LocationOnIcon />
      </IconButton>
    </div>
  );
}

export default Bandeau;
