import React, { useState, useEffect } from "react";

import { Button, TextField, Box, Typography, IconButton } from "@mui/material";
//import InputAdornment from "@mui/material/InputAdornment";
//import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
//import KeyIcon from "@mui/icons-material/Key";
import CloseIcon from "@mui/icons-material/Close";
import "../Styles/loginform.css";

function LoginForm({ onLogin, onSignUp }) {
  const [opened, setOpened] = useState(false);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);

  useEffect(() => {
    setPasswordError(password !== confirmPassword);
  }, [password, confirmPassword]);

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    setEmailError(!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value)); // Vérification simplifiée de l'email
  };
  const handlePasswordChange = (event) => {
    setEmailError(password === confirmPassword); // Vérification simplifiée de l'email
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submit action
    if (isSignUp) {
      if (password !== confirmPassword) {
        alert("Passwords do not match!");
        return;
      }
      onSignUp(email, username, password); // Assuming you have an onSignUp function
    } else {
      onLogin(email, password);
    }
  };

  const handleForgetPassword = (event) => {};

  const toggleForm = () => {
    setIsSignUp(!isSignUp);
    resetForm();
  };

  const resetForm = () => {
    setEmail("");
    setPassword("");
    setConfirmPassword("");
  };

  return (
    <Box className="form-box">
      {!opened && (
        <div
          className="toggle-icon"
          onClick={() => {
            resetForm();
            setIsSignUp(false);
            setOpened(true);
          }}
        >
          <AccountCircleIcon /> Login/SignUp
        </div>
      )}
      {opened ? (
        <>
          <CloseIcon className="toggle-icon" onClick={() => setOpened(false)} />
          <Typography variant="h3" className="form-header">
            {isSignUp ? "Sign Up" : "Login"}
            <Button className="toggle-button" onClick={toggleForm}>
              {isSignUp ? "or Login ?" : "or Sign Up ?"}
            </Button>
          </Typography>
          <TextField
            className="text-field"
            margin="normal"
            label="Email"
            variant="outlined"
            type="email"
            value={email}
            onChange={handleEmailChange}
            error={emailError}
            defaultValue="Something that will stay there initially only"
            helperText={emailError ? "Please enter a valid email" : ""}
            placeholder="please enter your email"
            /*InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AlternateEmailIcon />
                </InputAdornment>
              ),
            }}*/
          />

          {isSignUp ? (
            <TextField
              className="text-field"
              margin="normal"
              label="Username"
              type="string"
              variant="outlined"
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              placeholder="please enter your username"
              /*InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <KeyIcon />
                </InputAdornment>
              ),
            }}*/
            />
          ) : null}
          <TextField
            className="text-field"
            margin="normal"
            label="Password"
            type="password"
            variant="outlined"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            placeholder="please enter your password"
            /*InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <KeyIcon />
                </InputAdornment>
              ),
            }}*/
          />
          {isSignUp ? (
            <TextField
              className="text-field"
              margin="normal"
              label="Confirm Password"
              type="password"
              value={confirmPassword}
              error={passwordError}
              helperText={passwordError ? "Please enter same password" : ""}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              variant="outlined"
              placeholder="please confirm your password"
              /*InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <KeyIcon />
                  </InputAdornment>
                ),
              }}*/
            />
          ) : (
            <Button className="forget-password" onClick={handleForgetPassword}>
              Forgot password?
            </Button>
          )}

          <Button
            variant="contained"
            className="submit-button"
            disabled={
              emailError ||
              email === "" ||
              (isSignUp &&
                (password !== confirmPassword ||
                  password === "" ||
                  username === ""))
            }
            onClick={handleSubmit}
          >
            {isSignUp ? "Sign Up" : "Login"}
          </Button>
        </>
      ) : null}
    </Box>
  );
}

export default LoginForm;
