import React from "react";

import axios from "axios";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";

import "../Styles/left.css";

function Left() {
  const addEvent = async () => {
    // Génération d'un nom d'événement aléatoire
    const eventName = `Event-${Math.floor(Math.random() * 1000)}`;

    // Envoi de la requête POST au backend
    try {
      const response = await axios.post(process.env.URL + "events/add", {
        name: eventName,
      });
      console.log("Event added:", response.data);
      alert(`Event "${eventName}" added successfully!`);
    } catch (error) {
      console.error("Failed to add event:", error);
      alert("Failed to add event.");
    }
  };

  return (
    <div className="left-top-div">
      <IconButton aria-label="Add Event" onClick={addEvent}>
        <AddIcon />
      </IconButton>
    </div>
  );
}

export default Left;
